import {useState} from 'react';
import Image from 'next/image';
import {Link, Skeleton, Typography} from '@mui/material';
import {shallowEqual, useSelector} from 'react-redux';
import {Section, Grid, Box} from '@jobseeker/lotus/lib/foundations';
import useLoadAds from '@jobseeker/common-core/lib/hooks/useLoadAds';
import {handleTracking} from '@jobseeker/common-core/lib/utils';

import type {CompanyWorkWithType} from '../types';
import type {AppState, AdsElementType} from '@jobseeker/common-core/lib/types';

const CompanyWorkWith = (props: CompanyWorkWithType) => {
  const [companyInfo, setCompanyInfo] = useState(Array<AdsElementType>);

  const {eventTag, heading, theme, slotId, isLargeXl} = props || {};

  const companies = useSelector(
    (state: AppState) => state.ads.companyWorkWith,
    shallowEqual
  );

  useLoadAds(slotId, setCompanyInfo, {}, false, !companies[0]?.imageUrl);

  const loadedCompanyInfo = companies[0]?.imageUrl ? companies : companyInfo;

  return (
    <Section textAlign="center">
      <Typography
        component={'h2'}
        sx={{
          textAlign: 'center',
          fontSize: {
            xxs: theme.typography.pxToRem(20),
            md: theme.typography.pxToRem(26),
          },
          fontWeight: {xxs: 600, md: 400},
          display: heading ? 'block' : 'flex',
          justifyContent: heading ? '' : 'center',
        }}
      >
        {heading ? (
          heading
        ) : (
          <Skeleton
            key={'skeleton-company'}
            animation="wave"
            width={150}
            height={24}
            sx={{
              textAlign: 'center',
            }}
          />
        )}
      </Typography>
      <Grid
        container
        sx={{
          justifyContent: 'space-evenly',
          maxWidth: {xxs: '100%', md: '90%'},
          borderTop: `${theme.spacing(0.125)} solid #d9d9d9`,
          borderBottom: `${theme.spacing(0.125)} solid #d9d9d9`,
          marginTop: {xxs: theme.spacing(3.125), md: theme.spacing(5.625)},
          marginLeft: {
            sm: theme.spacing(5),
            md: theme.spacing(5.625),
            lg: theme.spacing(9.375),
            xl: theme.spacing(9.375),
          },
        }}
      >
        {loadedCompanyInfo[0]?.imageUrl
          ? loadedCompanyInfo.map((company, index) => (
              <Grid key={`companyworkwith-${index}`} item xxs={4} sm={1} md={2}>
                <Link
                  margin={`${theme.spacing(3.125)} 0`}
                  padding={`0 ${theme.spacing(2.5)}`}
                  boxSizing="border-box"
                  width="16%"
                  href={company['link']}
                  target="_blank"
                  onClick={() => {
                    handleTracking(eventTag, {
                      event_category: 'engagement',
                      Label: company['title'],
                      Value: `${index}`,
                    });
                  }}
                >
                  <Box>
                    <Image
                      src={company['imageUrl']}
                      alt={company['title']}
                      width={isLargeXl ? 150 : 100}
                      height={isLargeXl ? 120 : 80}
                      placeholder="blur"
                      blurDataURL={company['imageUrl']}
                      style={{
                        maxWidth: theme.spacing(25),
                        cursor: 'pointer',
                      }}
                    />
                  </Box>
                </Link>
              </Grid>
            ))
          : [...new Array(7)].map((p, i) => (
              <Skeleton
                key={`workwith-company-skeleton-${i}`}
                animation="wave"
                sx={{
                  width: {xxs: 100, md: 150},
                  height: {xxs: 80, md: 160},
                }}
              />
            ))}
      </Grid>
    </Section>
  );
};
export default CompanyWorkWith;
